export const imgThinkspikeLogo = require('../assets/image_logo.png').default;

export const imgDashboard = require('../assets/image_dashboard.png').default;
export const imgAnalytics = require('../assets/image_analytics.png').default;
export const imgClients = require('../assets/image_clients.png').default;
export const imgContentManagement = require('../assets/image_content_management.png').default;
export const imgCourses = require('../assets/image_courses.png').default;
export const imgPackages = require('../assets/image_packages.png').default;
export const imgPhone = require('../assets/image_phone.png').default;
export const imgUser = require('../assets/image_user.png').default;

export const imgLogout = require('../assets/image_logout.png').default;

export const imgChevronRight = require('../assets/image_chevron_right.svg').default;
export const imgSave = require('../assets/button_save.svg').default;

export const imgAllCourses = require('../assets/button_all_courses.svg').default;

export const imgCopy = require('../assets/image_copy.png').default;
export const imgRightArrow = require('../assets/right_arrow.png').default;
export const imgcheckboxIcon = require('../assets/checkbox_icon.png').default;
