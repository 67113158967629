import React from "react";
import {
  Modal,
  Container,
  Box,
  Button,
  Table,
  TableHead,
  TableBody,
  TableContainer,
  TableCell,
  TableRow,
  Paper,
  Typography,
  Input,
} from "@mui/material";
import {
  createTheme,
  ThemeProvider,
  Theme,
  StyledEngineProvider,
} from "@mui/material/styles";

import ContactusController, { Props } from "./ContactusController";

// Customizable Area Start
import { styled } from "@mui/material/styles";
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { 
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Checkbox,
  TablePagination,
  InputBase,
  TextField,
  Dialog, DialogTitle, DialogContent, DialogActions,FormLabel,Divider
} from "@mui/material";
import Sidebar from "./sidebar/Sidebar.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

const rows = [
  {
    "id": "1633",
    "type": "contact",
    "attributes": {
      "name": "Mohd Akibuddin",
      "email": "akeebudeen786@gmail.com",
      "phone_number": null,
      "description": "Query about product pricing",
      "subject": "Product Pricing",
      "company": "Innovate Inc",
      "job_title": "Product Manager",
      "read": true,
      "replied": false,
      "status": "pending",
      "created_at": "2024-09-29T03:59:14.734Z",
      "category": null
    }
  },
  {
    "id": "1638",
    "type": "contact",
    "attributes": {
      "name": "Mohd Akibuddin",
      "email": "akeebudeen786@gmail.com",
      "phone_number": null,
      "description": "Query about product pricing",
      "subject": "Product Pricing",
      "company": "Innovate Inc",
      "job_title": "Product Manager",
      "read": false,
      "replied": false,
      "status": "pending",
      "created_at": "2024-09-29T03:57:47.178Z",
      "category": null
    }
  },
  {
    "id": "1646",
    "type": "contact",
    "attributes": {
      "name": "Mohd Akibuddin",
      "email": "akeebudeen786@gmail.com",
      "phone_number": null,
      "description": "hello this is test description jkdhkajshdjka jksbasbdfhdsf jsbdfhjsdbfhsdf  hello this is test description jkdhkajshdjka jksbasbdfhdsf jsbdfhjsdbfhsdf",
      "subject": "Product Pricing",
      "company": "Innovate Inc",
      "job_title": "Product Manager",
      "read": false,
      "replied": false,
      "status": "pending",
      "created_at": "2024-09-29T03:57:47.178Z",
      "category": null
    }
  },
  {
    "id": "1639",
    "type": "contact",
    "attributes": {
      "name": "Mohd Akibuddin",
      "email": "akeebudeen786@gmail.com",
      "phone_number": null,
      "description": "Query about product pricing",
      "subject": "Product Pricing",
      "company": "Innovate Inc",
      "job_title": "Product Manager",
      "read": false,
      "replied": false,
      "status": "completed",
      "created_at": "2024-09-29T03:57:47.178Z",
      "category": null
    }
  },
  {
    "id": "1640",
    "type": "contact",
    "attributes": {
      "name": "Mohd Akibuddin",
      "email": "akeebudeen786@gmail.com",
      "phone_number": null,
      "description": "Query about product pricing",
      "subject": "Product Pricing",
      "company": "Innovate Inc",
      "job_title": "Product Manager",
      "read": false,
      "replied": false,
      "status": "pending",
      "created_at": "2024-09-29T03:57:47.178Z",
      "category": null
    }
  },
  {
    "id": "1641",
    "type": "contact",
    "attributes": {
      "name": "Mohd Akibuddin",
      "email": "akeebudeen786@gmail.com",
      "phone_number": null,
      "description": "Query about product pricing",
      "subject": "Product Pricing",
      "company": "Innovate Inc",
      "job_title": "Product Manager",
      "read": false,
      "replied": false,
      "status": "pending",
      "created_at": "2024-09-29T03:57:47.178Z",
      "category": null
    }
  },
  {
    "id": "1648",
    "type": "contact",
    "attributes": {
      "name": "Mohd Akibuddin",
      "email": "akeebudeen786@gmail.com",
      "phone_number": null,
      "description": "Query about product pricing",
      "subject": "Product Pricing",
      "company": "Innovate Inc",
      "job_title": "Product Manager",
      "read": false,
      "replied": false,
      "status": "pending",
      "created_at": "2024-09-29T03:57:47.178Z",
      "category": null
    }
  }, {
    "id": "1642",
    "type": "contact",
    "attributes": {
      "name": "Mohd Akibuddin",
      "email": "akeebudeen786@gmail.com",
      "phone_number": null,
      "description": "Query about product pricing",
      "subject": "Product Pricing",
      "company": "Innovate Inc",
      "job_title": "Product Manager",
      "read": false,
      "replied": false,
      "status": "pending",
      "created_at": "2024-09-29T03:57:47.178Z",
      "category": null
    }
  }, {
    "id": "1643",
    "type": "contact",
    "attributes": {
      "name": "Mohd Akibuddin",
      "email": "akeebudeen786@gmail.com",
      "phone_number": null,
      "description": "Query about product pricing",
      "subject": "Product Pricing",
      "company": "Innovate Inc",
      "job_title": "Product Manager",
      "read": false,
      "replied": false,
      "status": "pending",
      "created_at": "2024-09-29T03:57:47.178Z",
      "category": null
    }
  },
]
// Customizable Area End

export default class Contactus extends ContactusController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      // Customizable Area Start
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          {/* Customizable Area Start */}
          <Box sx={styles.mainBlock}>
            <Sidebar current='contact_us_queries' />

            <Box sx={styles.pageContent} data-testid="pageContent">
              <Box sx={styles.contentHeader}>
                <Box sx={styles.navigation}>
                  <Box sx={styles.breadcrumbButton}>Contact us Queries</Box>
                </Box>
              </Box>
              <TableContainer component={Paper} elevation={0} sx={styles.tableContainer}>
                <Table sx={{
                  borderCollapse: 'collapse',
                  border: 'none',
                  backgroundColor: '#f6f6f6',
                  boxShadow: 'none'
                }
                }>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={styles.cellWrapper}></TableCell>
                      <TableCell sx={styles.cellWrapper}></TableCell>
                      <TableCell sx={styles.cellWrapper}></TableCell>
                      <TableCell sx={styles.cellWrapper}></TableCell>
                      <TableCell sx={styles.cellWrapper}></TableCell>
                      <TableCell sx={styles.cellWrapper}></TableCell>
                      <TableCell sx={styles.cellWrapper}></TableCell>
                      <TableCell sx={styles.cellWrapper}></TableCell>
                      <TableCell sx={styles.cellWrapper}></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.slice(this.state.page * 8, this.state.page * 8 + 8).map((row, index) => (
                      <TableRow key={row.id} sx={{ borderBottom: 'none' }}>
                        <TableCell sx={styles.cellWrapper}>
                          <Checkbox
                            checked={false}
                            //onChange={() => handleCheckboxChange(index)}
                            color="info"
                          />
                        </TableCell>
                        <TableCell sx={styles.cellWrapper}><Box sx={styles.cellContainer}>
                          <Box sx={styles.subject}>{row.attributes.subject}</Box><Box
                            sx={styles.descriptionContainer}
                          >
                            <Typography
                              variant="body1"
                              sx={styles.descriptionText}
                            >
                              {row.attributes.description}
                            </Typography>
                          </Box></Box></TableCell>
                        <TableCell sx={styles.cellWrapper}>
                          <FormControl fullWidth variant="outlined">
                            <InputLabel id={`occupation-label-${row.id}`} sx={styles.categoryText}>Category</InputLabel>
                            <Select
                              labelId={`occupation-label-${row.id}`}
                              value={row.attributes.category || ''}
                              sx={styles.categoryContainer}
                              //onChange={handleOccupationChange(row.name)}
                              label="Select Occupation"
                            >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              <MenuItem value="Profile change">Profile change</MenuItem>
                              <MenuItem value="Access issues">Access issues</MenuItem>
                              <MenuItem value="Certification request">Certification request</MenuItem>
                              <MenuItem value="Add new category">Add new category</MenuItem>
                            </Select>
                          </FormControl>
                        </TableCell>
                        <TableCell sx={styles.cellWrapper}><Box sx={styles.cellContainer}><Box sx={styles.name}>{row.attributes.name}</Box><span>{row.attributes.email}</span></Box></TableCell>
                        <TableCell sx={styles.cellWrapper}><Box sx={styles.cellInfo}>{row.attributes.company}</Box></TableCell>
                        <TableCell sx={styles.cellWrapper}><Box sx={styles.cellInfo}>{this.formatDate(row.attributes.created_at)}</Box></TableCell>
                        <TableCell sx={styles.cellWrapper}>
                          <FormControl fullWidth variant="outlined" sx={{ border: 'none', borderWidth: '0px' }}>
                            <Select
                              labelId={`status-label-${row.id}`}
                              value={row.attributes.status}
                              sx={row.attributes.status==='pending' ? styles.statusContainer: styles.statusContainerSuccess}
                              //onChange={handleOccupationChange(row.name)}
                              label=""
                            >
                              <MenuItem value="pending">PENDING</MenuItem>
                              <MenuItem value="completed">COMPLETED</MenuItem>
                            </Select>
                          </FormControl></TableCell>
                        <TableCell sx={styles.cellWrapper}><Box data-test-id='btnReply' sx={styles.replyContainer} onClick={() => this.openModal(row)}> <Box sx={styles.replyText}>Reply</Box></Box></TableCell>
                        <TableCell sx={styles.cellWrapper}>{'...'}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <CustomDialog
                data-test-id="dialogContainer"
                open={this.state.isModalOpen && this.state.currentModalItem?.attributes}
                scroll="body"
                sx={{paddingTop:'10px',paddingBottom:'10px'}}
                onClose={() => this.closeModal()}
              >{this.state.currentModalItem?.attributes ? <>
                <DialogTitle className="dialog-title">
                  <ArrowBackIcon data-test-id="btnClose" className="arrow-icon" onClick={() => this.closeModal()} />
                  <Typography className="heading">Reply to: {this.state.currentModalItem.attributes.name}</Typography>
                  <CloseIcon className="close-icon" data-test-id="btnClose" onClick={() => this.closeModal()} />
                </DialogTitle>
                <DialogContent className="content">
                  <Box sx={styles.modalDesc}><Box sx={styles.modalText}>{this.state.currentModalItem.attributes?.description}</Box></Box>

                  <Box sx={[styles.modalItemCol,{marginTop:'10px'}]}>
                    <Box fontStyle={styles.modalName}>{this.state.currentModalItem.attributes.name}</Box><Box fontStyle={styles.modalName}>{this.state.currentModalItem.attributes.company}</Box>
                  </Box>
                  <Box sx={styles.modalItemCol}>
                    <Box fontStyle={styles.modalSubInfo}>{this.state.currentModalItem.attributes.job_title}</Box><Box fontStyle={styles.modalSubInfo}>{this.state.currentModalItem.attributes.email}</Box>
                  </Box>
                  <Divider sx={{marginTop:'20px'}} flexItem />

                  <Box sx={{ paddingTop: '20px' }}>
                    <FormControl fullWidth variant="outlined" sx={{}}>
                      <FormLabel component="legend">Category</FormLabel>
                      <Select
                        labelId={`category-label-select`}
                        value={this.state.currentModalItem.attributes.category || ''}
                        sx={{}}
                        placeholder="Select category"
                      //onChange={handleOccupationChange(row.name)}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value="Profile change">Profile change</MenuItem>
                        <MenuItem value="Access issues">Access issues</MenuItem>
                        <MenuItem value="Certification request">Certification request</MenuItem>
                        <MenuItem value="Add new category">Add new category</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                  <Box sx={{ marginTop: '20px' }}>
                    <FormControl fullWidth variant="outlined" >
                      <FormLabel component="legend">Your reply</FormLabel>
                      <TextField
                        //placeholder="Multi-line input"
                        variant="outlined"
                        value={this.state.replyText}
                        onChange={(e: any) => this.onChangeReplyText(e)}
                        multiline
                        rows={4}

                        sx={{minHeight: '160px' }}
                      /></FormControl>
                  </Box>
                </DialogContent>
                <DialogActions className="dialog-action" sx={{marginBottom:'10px'}}>
                  <CancelButton data-test-id="btnClose" onClick={this.closeModal}>
                    Cancel
                  </CancelButton>
                  <DoneButton
                    type="submit"
                    data-test-id="btnClose"
                    onClick={() => this.closeModal()}
                  >
                    Reply
                  </DoneButton>
                </DialogActions>
              </> : null}
              </CustomDialog>
              <Box sx={styles.paginationContainer}>
                <TablePagination
                  rowsPerPageOptions={[]}
                  component="div"
                  count={rows.length}
                  rowsPerPage={8}
                  page={this.state.page}
                  onPageChange={this.handleChangePage}
                //onRowsPerPageChange={()=>{}} 
                /></Box>
            </Box>
          </Box>

          <Box sx={styles.footerBlock}>
            <Box sx={styles.leftFooter}>
              <Box>Terms and Conditions</Box>
              <Box>Pravicy Policy</Box>
              <Box></Box>
            </Box>

            <Box>
              <Box>© 2024 All Rights Reserved</Box>
            </Box>
          </Box>
          {/* Customizable End Start */}
        </ThemeProvider>
      </StyledEngineProvider>
      //Merge Engine End DefaultContainer
      // Customizable Area Start
    );
  }
}

// Customizable Area Start

const modalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const styles = {
  mainBlock: {
    display: 'flex',
    width:'100%',
    flexDirection: 'row',
    backgroundColor: '#f6f6f6'
  },
  cellContainer: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '312px',
    minWidth: '200px',
  },
  cellWrapper: {
    border: 'none',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: '8px',
    paddingRight: '8px',
    paddingBottom: '5px'
  },
  cellInfo: {
    fontSize: '12px',
    fontWeight: '700'
  },
  modalItemCol:{
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  modalName: {
    fontSize: '14px',
    fontWeight: '700',
    lineHeight: '22px'
  },
  modalSubInfo: {
    fontSize: '12px',
    fontWeight: '400',
    lineHeight: '18px'
  },
  descriptionContainer: {
    display: 'flex',
    flexWrap: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: '280px',
  },
  descriptionText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: '14px',
    color: '#64748B',
    whiteSpace: 'nowrap',
    maxWidth: '100%',
  },
  categoryContainer: {
    width: '110px',
    height: '42px',
    justifyContent: 'center',
    textAlign: 'center',
    gap: '2px',
    borderRadius: '8px',
    border: '1px',
  },
  categoryText: {
    fontFamily: 'Heebo',
    fontSize: '12px',
    fontWeight: '700',
    lineHeight: '18px',
    textAlign: 'left',
  },
  subject: {
    color: '#0F172A',
    fontSize: '20px',
    fontWeight: '700',
    lineHeight: '28px'
  },
  name: {
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: '700',
    color: '#0F172A'
  },
  tableContainer: {
    boxShadow: 'none',
    maxWidth: '100%',
    marginLeft: '24px',
    border: 'none',
    overflowX: 'auto'
  },
  paginationContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  statusContainer: {
    width: '120px',
    height: '22px',
    fontSize: '12px',
    fontWeight: '700',
    lineHeight:'18px',
    color: '#D97706',
    justifyContent: 'center',
    alignContent:'center',
    textAlign: 'center',
    backgroundColor: '#FEF3C7',
    borderRadius: '26px',
  },
  statusContainerSuccess:{
    width: '120px',
    height: '22px',
    fontSize: '12px',
    fontWeight: '700',
    lineHeight:'18px',
    justifyContent: 'center',
    textAlign: 'center',
    backgroundColor: '#D1FAE5',
    borderRadius: '26px',
    color: '#059669'
  },
  completed: {
    background: '#D1FAE5'
  },
  pending: {
    background: '#FEF3C7'
  },
  modalDesc: {
    backgroundColor: '#F0F0F0',
    padding: '12px',
  },
  modalText: {
    color: '#44403C',
    fontSize: '16px', lineHeight: '24px',
    fontWeight: '400'
  },
  statusText: {
    fontFamily: 'Heebo',
    fontSize: '12px',
    fontWeight: '700',
    lineHeight: '18px',
    textAlign: 'left',
  },
  replyContainer: {
    backgroundColor: '#000000',
    width: '91px',
    height: '24px',
    textAlign: 'center',
    borderRadius: '4px',
    cursor: "pointer"
  },
  replyText: {
    color: '#FFFFFF',
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: '700',
  },
  footerBlock: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '24px 52px 24px 52px',
    backgroundColor: '#1C1C1C',
    color: '#fff'
  },
  leftFooter: {
    display: 'flex',
    gap: '24px'
  },
  rightFooter: {},

  sideBar: {
    height: '974px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '30px 16px 24px 40px',
    border: '1px solid #f5f5f5',
    backgroundColor: '#fff'
  },
  sideBarTop: {
    display: 'flex',
    flexDirection: 'column',
    gap: '40px',
  },
  sideNavList: {
    display: 'flex',
    flexDirection: 'column',
    gap: '24px'
  },
  evenRow: {
    backgroundColor: '#f5f5f5'
  },
  oddRow: {
    backgroundColor: '#ffffff'
  },
  sideNavItem: {
    height: '48px',
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
    padding: '4px 16px',
    borderRadius: '8px'
  },
  logoutButton: {
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
    color: 'red',
    flexGrow: '1',
    justifyContent: 'start',
    fontSize: '16px',
    textTransform: 'none'
  },
  pageContent: {
    display: 'flex',
    flexDirection: 'column'
  },
  contentHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '24px 48px'
  },
  navigation: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: '16px'
  },
  currentNavigationPage: {
    fontWeight: '700',
    fontSize: '24px',
    padding: '4px 8px'
  },
  breadcrumbButton: {
    fontSize: '24px',
    padding: '4px 8px'
  },
  leftActions: {
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
    fontSize: '24px'
  },
  blackButton: {
    backgroundColor: 'black',
    color: 'white',
    '&:hover': {
      backgroundColor: '#333333',
    },
    textTransform: 'none'
  },
  saveButton: {
    backgroundColor: 'black',
    color: 'white',
    '&:hover': {
      backgroundColor: '#333333',
    },
    height: '44px',
    padding: '10px 16px 6px',
    fontWeight: '700'
  },
  moreButton: {
    color: 'black',
    padding: '10px'
  },

  userContent: {
    marginLeft: '40px',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    gap: '26px'
  },
  greetingBlock: {
    width: '1069px',
    display: 'flex',
    flexDirection: 'row',
    padding: '24px 16px',
    background: '#FFFFFF'
  },
  adminGreeting: {
    display: 'inline-block',
    fontSize: '32px',
    whiteSpace: 'wrap',
    marginBottom: '16px',
    padding: '4px',
    borderRadius: '4px'
  },
  greetingBg: {
    background: '#F0F0F0',
    paddingRight: '16px',
    display: 'flex',
    flexDirection: 'row',
    borderRadius: '4px'
  },
  greetingInfo: {
    paddingLeft: '4px',
  },
  coursesWrapper: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  providerBlock: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    width: '290px',
    marginLeft: '16px'
  },
  editorContainer: {
    position: 'absolute',
    background: '#FFFFFF',
    borderRadius: '4px',
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
  },
  pathTitle: {
    fontSize: '24px',
    fontWeight: '500'
  },

  filterButtonsBlock: {
    display: 'flex',
    flexDirection: 'row',
    gap: '24px'
  },
  filterButton: {
    padding: '8px 16px 4px',
    fontSize: '16px'
  },
  courseCardsBlock: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: '40px',
    borderRadius: '4px'
  },
  courseCard: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    width: '240px'
  },
  courseCardDescription: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    padding: '0px 16px 24px 16px'
  },
  iconsStyle: {
    minWidth: '1px'
  },
  textRow: {
    whiteSpace: 'nowrap'
  }
}

const CustomDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    maxWidth: '720px',
    width: '100%',
    borderRadius: "8px 8px 25px",
    [theme.breakpoints.down('sm')]: {
      margin: "0px",
      maxWidth: "100% !important",
      borderRadius: "0px",
      height: "100vh",
    }
  },
  "& .MuiGrid-root > .MuiGrid-item": {
    paddingTop: "7px",
  },
  "& .dialog-title": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px 16px 10px 30px",
    borderBottom: "1px solid #E2E8F0",
    [theme.breakpoints.down('sm')]: {
      padding: "20px 15px 25px 20px",
      borderBottom: "0px",
      justifyContent: "normal",
    },
    "& .heading": {
      fontSize: "24px",
      fontFamily: "Heebo-Bold",
      [theme.breakpoints.down('sm')]: {
        width: "100%",
        fontSize: "14px",
        textAlign: "center",
        textTransform: "uppercase",
      },
    },
    "& .close-icon": {
      cursor: "pointer",
      color: "#334155",
      [theme.breakpoints.down('sm')]: {
        display: "none"
      },
    },
    "& .arrow-icon": {
      cursor: "pointer",
      display: "none",
      [theme.breakpoints.down('sm')]: {
        display: "block"
      },
    }
  },
  "& .content": {
    padding: "28px 40px !important",
    [theme.breakpoints.down('sm')]: {
      padding: "15px !important",
    },
    "& .mt5": {
      marginTop: "5px",
      fontSize: "16px",
      fontFamily: "Heebo-Regular",
    }
  },
  "& .contact-info": {
    fontSize: "16px",
    color: "#0F172A",
    marginBottom: "15px",
    fontFamily: "Heebo-Regular"
  },
  "& .label-title": {
    fontSize: "14px",
    color: "#9D9D9D",
    fontFamily: "Heebo-Bold",
    [theme.breakpoints.down('sm')]: {
      color: "#6A6A6A"
    },
  },
  "& .cool-gray": {
    color: "#64748B"
  },
  "& .dialog-action": {
    padding: "10px 16px 0px",
    borderTop: "1px solid #E2E8F0",
    [theme.breakpoints.down('sm')]: {
      display: "none"
    },
  },
  "& .send-btn": {
    display: "none",
    [theme.breakpoints.down('sm')]: {
      display: "block"
    },
  }
}));

const CustomInput = styled(InputBase)({
  border: "1px solid #CBD5E1",
  borderRadius: "8px",
  fontFamily: "Heebo-Regular",
  backgroundColor: "#fff",
  marginTop: "3px",
  fontSize: "14px",
  "& input": {
    padding: "7px 8px",
  },
  "& .MuiInputBase-inputMultiline": {
    fontSize: "14px",
    padding: "5px 8px",
  }
});

const CancelButton = styled(Button)({
  width: "80px",
  textTransform: "none",
  fontFamily: "Heebo-Bold",
  color: "black !important",
  fontSize: "16px !important",
  padding: "7px 15px !important",
  backgroundColor: "#F0F0F0",
  borderRadius: "8px !important",
  "&:hover": {
    backgroundColor: "#F0F0F0",
  },
});

const DoneButton = styled(Button)({
  marginLeft: "15px !important",
  width: "80px",
  textTransform: "none",
  fontFamily: "Heebo-Bold",
  color: "white !important",
  fontSize: "16px !important",
  padding: "7px 15px !important",
  backgroundColor: "black",
  borderRadius: "8px !important",
  "&:hover": {
    backgroundColor: "black",
  },
});

const SendButton = styled(Button)({
  textTransform: "none",
  marginTop: "50px",
  color: "#64748B !important",
  fontSize: "16px !important",
  padding: "12px 28px !important",
  fontFamily: "Heebo-Bold",
  borderRadius: "8px !important",
  backgroundColor: "#F0F0F0",
  "&:hover": {
    backgroundColor: "#F0F0F0",
  },
});

// Customizable Area End
