import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { apiCall } from "../../../components/src/ApiCall.web";
import React from 'react';
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start
export interface ITermsConds {
  id: string;
  description: string;
  is_accepted: boolean;
  created_at: string;
}

export interface ITermsCondsList {
  id: string,
  type: string,
  attributes: {
    id: number,
    description: string
  }
}

interface IUser {
  account_id: number,
  accepted_on: string,
  email: string
}

export interface IPrivacyPolicyData {
  id: string,
  type: string,
  attributes: {
    id: number,
    description: string,
    created_at: string,
    accepted_by: IUser[]
  }
}

export type SelectedPage = "main" | "adminEmail" | "helpCentre" | "TermsAndConditions" | "privacyPolicy"
export type SelectedEditorType = "termsAndConditions" | "privacyPolicy" | "none"

// Customizable Area End
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  currentView: SelectedPage,
  editor: {
    isEditorActive: boolean,
    editorState: string,
    type: SelectedEditorType
  }
  termsCondsList: ITermsCondsList | null
  termsAndConditionsValue: string
  privacyPolicyData: IPrivacyPolicyData | null
  privacyPolicy: string,
  loading: boolean,
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class TermsConditionsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  termsAndConditionsApiCallId: string = "";
  privacyPolicyApiCallId: string = "";
  editorRef: React.RefObject<HTMLDivElement>;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      currentView: "main",
      editor: {
        isEditorActive: false,
        editorState: "",
        type: 'none'
      },
      termsAndConditionsValue: "",
      privacyPolicy: "Privacy",
      termsCondsList: null,
      privacyPolicyData: null,
      loading: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.editorRef = React.createRef<HTMLDivElement>()
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    // Customizable Area Start
    document.addEventListener('mousedown', this.handleClick);
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area End
  }

  getToken = () => {
    const message: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(message);
  };

  componentWillUnmount(): Promise<void> {
    return new Promise((resolve) => {
    // Your cleanup logic here, if needed
      console.log('Component will unmount');

      // Resolve the promise immediately
      resolve();
    });
  }

  postTermsAndConditions = async () => {
    this.setState({ loading: true })
    this.termsAndConditionsApiCallId = await apiCall({
      contentType: "application/json",
      method: "PATCH",
      token: localStorage.getItem("token"),
      endPoint: `bx_block_terms_and_conditions/terms_and_conditions/${this.state.termsCondsList?.id}`,
      body: {
        "terms_and_condition": {
            "description": `${this.state.termsAndConditionsValue}`
        }
    },
    });
  }

  getTermsAndConditions = async () => {
    this.setState({ loading: true })
    this.termsAndConditionsApiCallId = await apiCall({
      method: "GET",
      contentType: "application/json",
      navigation: this.props.navigation,
      token: localStorage.getItem("token"),
      endPoint: `bx_block_terms_and_conditions/terms_and_conditions/latest_record`,
    });
  }

  getPrivacyPolicy = async () => {
    this.setState({ loading: true })
    this.privacyPolicyApiCallId = await apiCall({
      method: "GET",
      contentType: "application/json",
      navigation: this.props.navigation,
      token: localStorage.getItem("token"),
      endPoint: `bx_block_terms_and_conditions/privacy_policies/latest_record`,
    });
  }

  postPrivacyPolicy = async () => {
    this.setState({ loading: true })
    this.termsAndConditionsApiCallId = await apiCall({
      contentType: "application/json",
      method: "POST",
      token: localStorage.getItem("token"),
      endPoint: `bx_block_terms_and_conditions/privacy_policies`,
      body: {
        "privacy_policy": {
            "description": `${this.state.privacyPolicy}`
        }
    },
    });
  }

  handleClick = (event: any) => {
    if (this.editorRef.current && !this.editorRef.current.contains(event.target)) {
      this.handleClickOutsideEditor();
    }
  };

  handleClickOutsideEditor = () => {
    this.onSaveEditor();
    this.setState({ editor: { ...this.state.editor, isEditorActive: false } })
  };

  onSaveEditor = () => {
    switch (this.state.editor.type) {
      case "termsAndConditions":
        this.setState({ termsAndConditionsValue: this.state.editor.editorState })
        this.postTermsAndConditions()
        break;
      case "privacyPolicy":
        this.setState({ privacyPolicy: this.state.editor.editorState })
        this.postPrivacyPolicy()
        break;
      default:
        break;
    }
  }

  handleViewChange = (view: SelectedPage) => {
    if (view === "TermsAndConditions") {
      this.getTermsAndConditions()
    } else if (view === "privacyPolicy") {
      this.getPrivacyPolicy();
    }

    this.setState({ currentView: view })
  }

  handleEditorChange = (result: any) => {
    this.setState({ editor: { ...this.state.editor, editorState: result } })
  }

  onOpenEditor = (type: SelectedEditorType) => {
    switch (type) {
      case "termsAndConditions":
        this.setState({ editor: { isEditorActive: true, editorState: this.state.termsAndConditionsValue, type } });
        break;
      case "privacyPolicy":
        this.setState({ editor: { isEditorActive: true, editorState: this.state.privacyPolicy, type } });
        break;
      default:
        break;
    }
  }
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.termsAndConditionsApiCallId) {
          if (responseJson.data) {
            const responseData = responseJson.data as ITermsCondsList
            this.setState({ termsCondsList: responseData, termsAndConditionsValue: responseData.attributes.description })
          } else if (responseJson.error) {
            toast.error(responseJson.error);
          }
        }

        if (apiRequestCallId === this.privacyPolicyApiCallId) {
          if (responseJson.data) {
            const responseData = responseJson.data as IPrivacyPolicyData
            this.setState({ privacyPolicyData: responseData, privacyPolicy: responseData.attributes.description })
          } else if (responseJson.error) {
            toast.error(responseJson.error);
          }
        }

        this.setState({ loading: false });
      }
    }
    // Customizable Area End
  }
}
