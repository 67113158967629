//@ts-nocheck
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { ReactNode } from "react";

// Customizable Area Start

// Customizable Area End

export const configJSON = require("./config");


export interface Props {
    // Customizable Area Start
    children: ReactNode;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    requirementsModal: {
        isVisible: boolean
    },
    totalHours: number,
    passingPercentage: number,
    courseRequirements: string
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

// Customizable Area Start
interface IValues {
    totalHours: number,
    passingPercentage: number,
    courseRequirements: string
}
// Customizable Area End


export default class CourseCreationHeaderController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start

    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        // Customizable Area End
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area Start
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            requirementsModal: {
                isVisible: false
            },
            totalHours: 0,
            passingPercentage: 0,
            courseRequirements: "",
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start

        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start

        // Customizable Area End
    }

    // Customizable Area Start

    onHideRequirementsModal = () => {
        this.setState({requirementsModal: { isVisible: false }})
    }

    submitCourseRequirements = (values: IValues) => {
        console.log(values)

        this.onHideRequirementsModal();
    }

    handleNumericInput = (e: React.FormEvent<HTMLInputElement>) => {
        const inputElement = e.target as HTMLInputElement;
        const value = inputElement.value;
    
        if (!/[0-9]/.test(value)) {
            inputElement.value = value.replace(/\D/g, '');
        }
    };

    // Customizable Area End
}
