//@ts-nocheck
import React, { ChangeEvent } from "react";
// Customizable Area Start
import {
  InputBase, Box, Button, List, ListItem, Grid, Typography,
  ToggleButtonGroup, ToggleButton, Drawer, IconButton, Slider,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { styled } from "@mui/material/styles";
import CloseIcon from '@mui/icons-material/Close';
import {
  addImage, addImageLight, imageArrowRight, imageArrowUp,
  imageListBulleted, imageListEmpty, imageListNumbered, trashImage,
  plusIcon, copyIcon, editIcon, formateIcon, deleteIcon, textIcon, listIcon,
  videoIcon, tableIcon, flashcardIcon, carouselIcon, buttonIcon, imageIcon,
} from "./assets";
import ContentManagementController, {
  DefaultInputTitle, Props, configJSON,
} from "./ContentManagementController";
import CourseCreationHeader from "./CourseCreationHeader.web";
import Editor from "./Editor.web";
import PopupMenu from "./PopupMenu.web";
import CustomEditor from "../../../components/src/CustomEditor.web";

const toolbarItems = [
  { label: 'Text', icon: textIcon },
  { label: 'List', icon: listIcon },
  { label: 'Image', icon: imageIcon },
  { label: 'Video', icon: videoIcon },
  { label: 'Table', icon: tableIcon },
  { label: 'Flashcard', icon: flashcardIcon },
  { label: 'Carousel', icon: carouselIcon },
  { label: 'Button', icon: buttonIcon },
];

type ChangeTitleRenderProps = {
  state: DefaultInputTitle,
  inputStyles: React.CSSProperties;
  toggleActivity: (val: boolean) => void;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  dataTestId?: string
};
// Customizable Area End

export default class ContentManagement extends ContentManagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  getListStyleType = (number: number) => {
    if (this.state.lessonListType === "bullet") {
      return <Circle></Circle>
    } else if (this.state.lessonListType === "numbered") {
      return <div>{number}</div>
    }
  }

  ChangeTitleRender: React.FC<ChangeTitleRenderProps> = ({
    state,
    inputStyles,
    toggleActivity,
    onChange,
    placeholder,
    dataTestId
  }) => {
    return (
      <div data-test-id="change-title-render" onDoubleClick={() => toggleActivity(true)}>
        {state.isActiveToChange ?
          <CustomInput
            fullWidth
            autoFocus
            data-test-id={dataTestId}
            placeholder={placeholder}
            style={inputStyles}
            value={state.title}
            onKeyDown={(event) => this.onHandleEnterPress(event, () => toggleActivity(false))}
            onChange={(event) => onChange(event)}
          />
          :
          state.title?.length === 0 ?
            <div className="placeholder">{placeholder}</div>
            :
            state.title
        }
      </div>
    );
  }

  renderToolbar = () => {
    const isToolbarVisible = this.state.isToolbarVisible || this.state.courseData.length === 0;
    return (
      <>
        {this.state.courseData.length !== 0 &&
          <PlusLine onClick={this.openToolbar} data-test-id="plus-btn">
            <div />
            <img src={plusIcon} />
          </PlusLine>
        }
        <Toolbar isVisible={isToolbarVisible}>
          <Grid container spacing={2} justifyContent="center">
            {toolbarItems.map((item, index) => (
              <Grid item key={index}>
                <button data-test-id="content-type" onClick={() => this.handleTypeButtonClick(item.label)}>
                  <img src={item.icon} />
                  <span>{item.label}</span>
                </button>
              </Grid>
            ))}
          </Grid>
        </Toolbar>
      </>
    );
  }

  renderOptionList = (index: number) => {
    const isEditActive = this.state.currentIndex === index && this.state.buttonType === "edit";
    const isFormatActive = this.state.currentIndex === index && this.state.buttonType === "format";
    return (
      <OptionList className="option-list">
        <button><img src={editIcon} onClick={() => this.handleEditClick(index)} className={isEditActive ? "active" : ""} data-test-id="edit-btn" /></button>
        <button><img src={formateIcon} onClick={() => this.handleFormatClick(index)} className={isFormatActive ? "active" : ""} data-test-id="format-btn" /></button>
        <button><img src={copyIcon} onClick={() => this.handleCopyClick(index)} data-test-id="copy-btn" /></button>
        <button><img src={deleteIcon} onClick={() => this.handleDeleteClick(index)} data-test-id="delete-btn" /></button>
      </OptionList>
    );
  }

  renderFormatPopup = () => {
    return (
      <FormatList className="format-option">
        <Box>
          <Box display="flex" justifyContent="space-between">
            <Typography className="format">Format</Typography>
            <CloseIcon onClick={this.hideFormatOption} className="close-btn" data-test-id="close-btn" />
          </Box>
          <Box>
            <Typography className="text">Block Padding</Typography>
            <ToggleButtonGroup
              value="M"
              exclusive
              onChange={() => { }}
            >
              <CustomToggleButton value="S">S</CustomToggleButton>
              <CustomToggleButton value="M">M</CustomToggleButton>
              <CustomToggleButton value="L">L</CustomToggleButton>
            </ToggleButtonGroup>
          </Box>
          <Box>
            <Typography className="small-text">Top</Typography>
            <CustomSlider
              data-test-id="padding-top-btn"
              valueLabelDisplay="auto"
              value={this.state.courseData[this.state.currentIndex].paddingTop}
              onChange={(e) => this.handlePadding(e, "paddingTop")}
            />
          </Box>
          <Box>
            <Typography className="small-text">Bottom</Typography>
            <CustomSlider
              data-test-id="padding-bottom-btn"
              valueLabelDisplay="auto"
              value={this.state.courseData[this.state.currentIndex].paddingBottom}
              onChange={(e) => this.handlePadding(e, "paddingBottom")}
            />
          </Box>
          <Box>
            <Typography className="text">Content Padding</Typography>
            <ToggleButtonGroup
              value="M"
              exclusive
              onChange={() => { }}
            >
              <CustomToggleButton value="S">S</CustomToggleButton>
              <CustomToggleButton value="M">M</CustomToggleButton>
              <CustomToggleButton value="L">L</CustomToggleButton>
            </ToggleButtonGroup>
          </Box>
        </Box>
      </FormatList>
    );
  }

  renderTextDrawer = () => {
    return (
      <>
        <Typography className="title">
          Heading
        </Typography>
        <Box paddingBottom="15px">
          <CustomEditor
            value={this.state.currentObj.heading}
            onChange={this.onChangeHeading}
          />
        </Box>
        <Typography className="title">
          Paragraph
        </Typography>
        <Box paddingBottom="15px">
          <CustomEditor
            value={this.state.currentObj.description}
            onChange={this.onChangeDescription}
          />
        </Box>
      </>
    );
  }

  renderDrawerContent = () => {
    if (this.state.currentObj.type === "Text") {
      return this.renderTextDrawer();
    }
  }

  renderEditDrawer = () => {
    return (
      <CustomDrawer open={this.state.isDrawerOpen} onClose={this.handleDrawerToggle}>
        <Box sx={{ width: 640, padding: "30px", position: 'relative' }}>
          <IconButton
            data-test-id="close-drawer"
            onClick={this.handleDrawerToggle}
            sx={{ position: 'absolute', top: 16, right: 16 }}
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold' }}>
            Edit {this.state.courseData[this.state.currentIndex]?.type}
          </Typography>
          {this.renderDrawerContent()}
        </Box>
      </CustomDrawer>
    );
  }

  renderSidebarDrawer = () => {
    return (
      <CustomDrawer open={this.state.isSidebarOpen} onClose={this.sidebarHandler}>
        <Box className="content-sidebar">
          <Box display='flex' justifyContent='space-between' alignItems='center'>
            <Typography className="content-library">Content Library</Typography>
            <IconButton onClick={this.sidebarHandler} data-test-id="close-sidebar">
              <CloseIcon />
            </IconButton>
          </Box>
          <List>
            {['TEXT', 'LIST', 'IMAGE', 'VIDEO', 'TABLE', 'FLASH CARD', 'LABELED GRAPHIC', 'FLOW', 'QUOTE', 'TEST KNOWLEDGE', 'BUTTON'].map((text, index) => (
              <ListItem className="list-item" key={`${index + 1}`} onClick={() => this.handleTypeButtonClick(text.charAt(0) + text.slice(1).toLowerCase(), true)} data-test-id="sidebar-content-type">
                <Typography className="list-text">{text}</Typography>
              </ListItem>
            ))}
          </List>
        </Box>
      </CustomDrawer>
    );
  }

  renderTextType = (item, index: number) => {
    const isOptionVisible = this.state.currentIndex === index && (this.state.buttonType === "edit" || this.state.buttonType === "format");
    return (
      <TextWrapper isOptionVisible={isOptionVisible} sx={{ paddingTop: `${item.paddingTop}px`, paddingBottom: `${item.paddingBottom}px` }}>
        <div style={{ marginBottom: "30px" }} dangerouslySetInnerHTML={{ __html: item.heading }} className="margin0" />
        <div dangerouslySetInnerHTML={{ __html: item.description }} className="margin0" />
        {this.state.currentIndex === index && this.state.buttonType === "format" && this.renderFormatPopup()}
        {this.renderOptionList(index)}
      </TextWrapper>
    );
  }

  renderContentType = () => {
    return (
      <ContentWrapper>
        <Box className="course-wrapper">
          <IconButton onClick={this.sidebarHandler}>
            <MenuIcon />
          </IconButton>
          <Box padding="10px 30px 30px">
            <Box className="title">
              {this.ChangeTitleRender({
                state: this.state.lessonTitle,
                inputStyles: inputStyle.courseTitle,
                toggleActivity: (val) => this.onActiveLessonTitle(val),
                onChange: (event) => this.onChangeLessonTitle(event),
                placeholder: "Enter lesson title",
                dataTestId: "lesson-title-input"
              })}
            </Box>
            <Box className="author">
              {this.ChangeTitleRender({
                state: this.state.lessonAuthorName,
                inputStyles: inputStyle.authorName,
                toggleActivity: (val) => this.onActiveAuthorName(val),
                onChange: (event) => this.onChangeAuthorName(event),
                placeholder: "Author name",
                dataTestId: "author-name-input"
              })}
            </Box>
          </Box>
        </Box>
        <Box marginTop="50px">
          {this.state.courseData.map((item, index: number) => {
            return (
              <Box key={`${index + 1}`}>
                {item.type === "Text" && this.renderTextType(item, index)}
              </Box>
            )
          }
          )}
          {this.renderToolbar()}
        </Box>
      </ContentWrapper>
    );
  }
  // Customizable Area End

  render() {

    return (
      // Customizable Area Start
      <Wrapper data-test-id="wrapper">
        <CourseCreationHeader>
          {this.state.isLessonVisible && this.renderContentType()}
          {this.renderEditDrawer()}
          {this.renderSidebarDrawer()}
          {!this.state.isLessonVisible && 
          <>
          <CourseHead>
            <div className="course-wrapper">
              <div className="title">
                {this.ChangeTitleRender({
                  state: this.state.courseTitle,
                  inputStyles: inputStyle.courseTitle,
                  toggleActivity: (val) => this.onHandleSetCourseTitleActive(val),
                  onChange: (event) => this.onHandleCourseTitleChange(event),
                  placeholder: "Enter course title"
                })}
              </div>
              <div className="author">
                {this.ChangeTitleRender({
                  state: this.state.authorName,
                  inputStyles: inputStyle.authorName,
                  toggleActivity: (val) => this.onHandleSetAuthorNameTitleActive(val),
                  onChange: (event) => this.onHandleAuthorNameTitleChange(event),
                  placeholder: "Enter author name"
                })}
              </div>
            </div>
          </CourseHead>
          <CourseDescription data-test-id="description-form">
            <AddButton style={buttonStyle.lessonListTypeAddButton} data-test-id="lesson-type-add-button" onClick={(event) => this.onToggleLessonTypeMenu(event)}>
              <img src={addImageLight}></img>
            </AddButton>
            <PopupMenu ref={this.lessonTypeMenuRef}>
            <LessonTypeMenu data-test-id="lesson-type-menu">
                <AddButton style={buttonStyle.lessonListTypeButton} data-test-id="lesson-type-button-empty" onClick={() => this.setLessonListType("empty")}><img src={imageListEmpty} /></AddButton>
                <AddButton style={buttonStyle.lessonListTypeButton} data-test-id="lesson-type-button-numbered" onClick={() => this.setLessonListType("numbered")}><img src={imageListNumbered} /></AddButton>
                <AddButton style={buttonStyle.lessonListTypeButton} data-test-id="lesson-type-button-bullet" onClick={() => this.setLessonListType("bullet")}><img src={imageListBulleted} /></AddButton>
              </LessonTypeMenu>
            </PopupMenu>
            <div className="course-description" onDoubleClick={this.onHandleOpenEditorMenu}>
              <span dangerouslySetInnerHTML={{ __html: this.state.description }}></span>
            </div>
            <PopupMenu ref={this.editorMenuRef}>
              <Editor defaultValue={this.state.description} onSave={this.onEditorSave} className="editor"></Editor>
            </PopupMenu>
          </CourseDescription>
          <LessonList>
            <DragDropContext onDragEnd={this.onDragEnd}>
              <Droppable droppableId="lessons">
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {this.state.lessonList.length > 0 &&
                      this.state.lessonList.map((lessonItem, index) => {
                        if (!this.lessonPopupMenuRefs[lessonItem.id]) {
                          this.lessonPopupMenuRefs[lessonItem.id] = React.createRef();
                        }
                        return (
                          <Draggable key={lessonItem.id} draggableId={lessonItem.id.toString()} index={index}>
                          {(provided) => (
                            <LessonBlock
                              key={lessonItem.id}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <div style={{ display: "flex", alignItems: "center", gap: 20 }}>
                                {this.getListStyleType(index + 1)}
                                <div className="lesson-title">
                                  {this.ChangeTitleRender({
                                    state: lessonItem.lessonTitle,
                                    inputStyles: inputStyle.lessonTitle,
                                    toggleActivity: (val) => this.onHandleSetLessonTitleActive(lessonItem.id, val),
                                    onChange: (event) => this.onHandleLessonTitleChange(event, lessonItem.id),
                                    placeholder: "Enter lesson title"
                                  })}
                                </div>
                              </div>
                              <div style={{ display: "flex", alignItems: "center", gap: 16 }}>
                                <div style={{ position: "relative" }}>
                                  <EditLessonButton data-test-id="edit-button" onClick={(event) => this.onOpenLessonPopupMenu(event, lessonItem.id)}>
                                    {lessonItem.isPopUpActive ?
                                      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", gap: 8, marginRight: "-8px" }}><span>{configJSON.labelAddLesson}</span><img src={imageArrowUp} /></div>
                                      :
                                      <span>{configJSON.labelEditLesson}</span>
                                    }
                                  </EditLessonButton>
                                  <PopupMenu onClose={this.onCloseLessonPopupMenu} ref={this.lessonPopupMenuRefs[lessonItem.id]}>
                                    <LessonPopupMenu data-test-id="lesson-popup-menu">
                                      <div data-test-id="lesson-creation-btn" onClick={() => this.onLessonCreation()} className="lesson-popup-menu-block"><span>{configJSON.labelCreateNewLesson}</span><img src={imageArrowRight} /></div>
                                      <div className="lesson-popup-menu-block"><span style={{ fontWeight: 700 }}>{configJSON.labelCreateNewQuiz}</span><img src={imageArrowRight} /></div>
                                    </LessonPopupMenu>
                                  </PopupMenu>
                                </div>
                                <DeleteButton
                                  data-test-id="delete-button"
                                  onClick={() => this.deleteLesson(lessonItem.id)}
                                >
                                  <img src={trashImage} />
                                </DeleteButton>
                              </div>
                            </LessonBlock>
                          )}
                        </Draggable>
                        )
                      })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            {this.state.lessonCreationBar.isActive &&
              <LessonCreator>
                <div style={{
                  ...inputStyle.inputBox,
                  ...(this.state.lessonList.length > 0 && { padding: "32px" })
                }}>

                  {this.getListStyleType(this.state.lessonList.length + 1)}
                  <CustomInput
                    fullWidth
                    autoFocus
                    style={inputStyle.lessonCreation}
                    data-test-id="lesson-title"
                    placeholder={configJSON.placeHolderLessonTitle}
                    value={this.state.lessonCreationBar.title}
                    onChange={(event) => {
                      this.setState({ lessonCreationBar: { title: event.target.value, isActive: true } });
                    }}
                    onKeyDown={(event) => this.onHandleEnterPress(event, () => this.addNewLesson(event.target.value))}
                  />
                </div>
                <div className="label-submit">{configJSON.labelPressEnterNewLesson}</div>
              </LessonCreator>
            }
            {!this.state.lessonCreationBar.isActive &&
              <AddButton data-test-id="add-lesson-button" style={buttonStyle.lessonAddButton} onClick={this.setCreationBarActive}><img src={addImage} /></AddButton>
            }
          </LessonList>
          </>}
          <div ref={this.bottomRef}></div>
        </CourseCreationHeader>
      </Wrapper>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const ContentWrapper = styled(Box)({
  "& .course-wrapper": {
    padding: "15px 56px 15px 24px",
    boxShadow: "0px 2px 20px 0px #00000014",
  },
  "& .title": {
    fontSize: "64px",
    lineHeight: "80px",
    marginBottom: "32px",
    fontFamily: 'Crimson Text',
  },
  "& .author": {
    fontFamily: "Heebo",
    fontWeight: 400,
    fontSize: "16px",
    color: "#1C1C1C",
  }
});

const TextWrapper = styled(Box, { shouldForwardProp: (prop) => prop !== "isOptionVisible" })<{ isOptionVisible: boolean }>(({ theme, isOptionVisible }) => ({
  position: "relative",
  borderTop: `1px solid ${isOptionVisible ? "#D7D7D7" : "transparent"}`,
  borderBottom: `1px solid ${isOptionVisible ? "#D7D7D7" : "transparent"}`,
  "& .option-list": {
    display: isOptionVisible ? "block" : "none",
    "& button": {
      padding: "0px",
      border: "none",
      outline: "none",
      background: "transparent"
    }
  },
  "&:hover": {
    borderTop: "1px solid #D7D7D7",
    borderBottom: "1px solid #D7D7D7",
    "& .option-list": {
      display: "block"
    },
  },
}));

const FormatList = styled(Box)({
  zIndex: "9999999",
  position: "absolute",
  top: "76px",
  right: "25px",
  display: "flex",
  gap: "10px",
  width: "fit-content",
  backgroundColor: "#fff",
  borderRadius: "4px",
  padding: "24px",
  boxShadow: "0px 2px 20px 0px #00000014",
  "& .close-btn": {
    cursor: "pointer"
  },
  "& .format": {
    fontFamily: "Heebo-Bold",
    fontSize: "16px"
  },
  "& .text": {
    marginTop: "20px",
    marginBottom: "20px",
    fontFamily: "Heebo-Regular",
    fontSize: "16px",
  },
  "& .small-text": {
    fontSize: "12px",
    marginTop: "15px",
    marginBottom: "5px",
    fontFamily: "Heebo-Medium",
  }
});

const CustomToggleButton = styled(ToggleButton)({
  borderRadius: '4px !important',
  padding: '5px 20px',
  width: "67px",
  color: "#000",
  background: "#fff",
  fontFamily: "Heebo-Regular",
  border: "1.5px solid #D6D3D1",
  '&.Mui-selected': {
    fontFamily: "Heebo-Bold",
    borderRadius: '4px',
    background: "#fff",
    borderColor: '#000',
    fontWeight: 'bold',
    border: "1.5px solid #1C1C1C"
  },
  "&.MuiToggleButtonGroup-middleButton": {
    margin: "0px 13px",
  },
  '&:not(.Mui-selected)': {
    border: "1.5px solid rgb(214, 211, 209)",
    backgroundColor: 'transparent',
  },
  '&:hover': {
    borderColor: '#000',
  },
});

const CustomSlider = styled(Slider)({
  color: '#000',
  height: 2,
  '& .MuiSlider-thumb': {
    height: 16,
    width: 16,
    backgroundColor: '#000',
    border: '2px solid currentColor',
  },
  '& .MuiSlider-track': {
    height: 2,
  },
  '& .MuiSlider-rail': {
    color: '#ccc',
    height: 2,
  },
});

const PlusLine = styled(Box)({
  marginBottom: "50px",
  cursor: "pointer",
  position: "relative",
  "& div": {
    borderBottom: "1px solid rgb(215, 215, 215)"
  },
  "& img": {
    position: "absolute",
    left: "50%",
    top: "-18px",
    padding: "10px",
    borderRadius: "50%",
    backgroundColor: "white",
    border: "1px solid #D7D7D7",
    boxShadow: "0px 2px 20px 0px #00000014"
  }
});

const Toolbar = styled(Box, { shouldForwardProp: (prop) => prop !== "isVisible" })<{ isVisible: boolean }>(({ theme, isVisible }) => ({
  display: isVisible ? "block" : "none",
  padding: "20px",
  width: "fit-content",
  margin: "20px auto 70px",
  borderRadius: "4px",
  border: "1px solid #A8A29E",
  "& button": {
    cursor: "pointer",
    outline: "none",
    border: "none",
    display: "flex",
    flexDirection: "column",
    padding: "12px 24px",
    alignItems: "center",
    borderRadius: "4px",
    backgroundColor: "transparent",
    "& span": {
      marginTop: "20px",
      fontSize: "18px",
      fontFamily: "Heebo-Regular",
    },
    "&:hover": {
      backgroundColor: "#D7D7D7"
    }
  }
}));

const OptionList = styled(Box)({
  position: "absolute",
  top: "10px",
  right: "25px",
  display: "flex",
  gap: "10px",
  width: "fit-content",
  backgroundColor: "#fff",
  borderRadius: "4px",
  padding: "10px 15px",
  boxShadow: "0px 2px 20px 0px #00000014",
  "& img": {
    cursor: "pointer",
    borderRadius: "4px",
    padding: "5px",
    "&:hover, &.active": {
      backgroundColor: "#D7D7D7",
    }
  }
});

const CustomDrawer = styled(Drawer)({
  "& .title": {
    color: "#6A6A6A",
    fontSize: "16px",
    marginTop: "30px",
    marginBottom: "20px",
    fontFamily: "Heebo-Medium",
  },
  "& .margin0": {
    "& h1, h2, h3, h4, p": {
      margin: "0px"
    }
  },
  "& .content-sidebar": {
    width: "250px",
    padding: "16px",
    "& .content-library": {
      fontSize: "24px",
      fontFamily: "Heebo-Bold"
    },
    "& .list-item": {
      cursor: "pointer",
      padding: "10px 0px"
    },
    "& .list-text": {
      fontSize: "16px !important",
      fontFamily: "Heebo-Bold !important"
    }
  }
});


const inputStyle = {
  courseCreation: {
    fontFamily: "Crimson Text",
    fontSize: "20px",
    fontWeight: 400,
    lineHeight: "32px",
    color: "#1C1C1C",
    "&::placeholder": {
      color: "#A8A29E"
    },
  },
  lessonCreation: {
    fontFamily: "Heebo",
    fontWeight: 400,
    fontSize: "18px",
    lineHeight: "26.44px",
  },
  inputBox: {
    borderBottom: "1px solid #9D9D9D",
    display: "flex",
    alignItems: "center",
    gap: 20,
    padding: "32px 0"
  },
  lessonTitle: {
    fontSize: "18px",
    fontFamily: "Heebo",
    fontWeight: 500,
    lineHeight: "26.44px",
    color: "#1C1C1C",
  },
  courseTitle: {
    height: "auto",
    fontFamily: 'Crimson Text',
    fontSize: "64px",
    color: "#000000",
    fontWeight: 400,
    lineHeight: "80px"
  },
  authorName: {
    fontFamily: "Heebo",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "23.5px",
    color: "#1C1C1C",
  }
}

const buttonStyle = {
  lessonAddButton: {
    position: "absolute",
    bottom: 0,
    left: "50%",
    transform: "translate(-50%, 50%)",
    "&:hover": {
      backgroundColor: "#1C1C1C",
    }
  },
  lessonListTypeAddButton: {
    boxShadow: "0px 2px 20px 0px #00000014",
  },
  lessonListTypeButton: {
    border: "1px solid #6A6A6A",
  }
}

const LessonPopupMenu = styled(Box)({
  width: "auto",
  backgroundColor: "#FFFFFF",
  boxShadow: "0px 2px 20px 0px #00000014",
  padding: "14px",
  borderRadius: "4px",
  "& .lesson-popup-menu-block": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: 32,
    textWrap: "nowrap",
    backgroundColor: "#FAFAF9",
    borderBottom: "1px solid #F5F5F4",
    padding: "16px",
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: "18px",
    lineHeight: "26px",
    color: "#1C1C1C",
    "& img": {
      width: 16,
      height: 16
    }
  }
})

const LessonTypeMenu = styled(Box)({
  boxShadow: "0px 2px 20px 0px #00000014",
  backgroundColor: "#FFFFFF",
  display: 'flex',
  gap: 16,
  padding: "12px 16px 12px 16px",
  top: "50%",
})

const AddButton = styled(Button)({
  width: 32,
  height: 32,
  minWidth: "auto",
  borderRadius: "50%",
})

const EditLessonButton = styled(Button)({
  padding: "4px 16px 4px 16px",
  borderRadius: "4px",
  border: "1px solid #1C1C1C",
  backgroundColor: "#FFFFFF",
  fontFamily: "Heebo",
  fontWeight: 500,
  fontSize: "12px",
  lineHeight: "18px",
  color: "#1C1C1C",
  textTransform: "none",
  "& img": {
    width: 24,
    height: 24,
  }
})

const DeleteButton = styled(Button)({
  width: 32,
  height: 32,
  minWidth: "auto",
  backgroundColor: "transparent"
})

const Circle = styled(Box)({
  width: 12,
  height: 12,
  borderRadius: "50%",
  background: "#1C1C1C",
})

const LessonCreator = styled(Box)({
  padding: "0 0 32px 0",
  "& .label-submit": {
    textAlign: "right",
    fontFamily: "Heebo",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "17.63px",
    marginTop: 12,
    color: "#6A6A6A",
  }
})

const LessonList = styled(List)({
  padding: "0 32px",
  maxWidth: "1064px",
  margin: '20px auto 0 auto',
  position: "relative"
})

const LessonBlock = styled(ListItem)({
  position: "relative",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "32px",
  background: "#FFFFFF",
  borderTop: "1px solid #9D9D9D",
  borderBottom: "1px solid #9D9D9D",
  fontSize: "18px",
  fontFamily: "Heebo",
  fontWeight: 500,
  lineHeight: "26.44px",
  color: "#1C1C1C",
  "&:hover": {
    backgroundColor: "#9D9D9D"
  },
});

const CourseHead = styled(Box)({
  boxShadow: "0px 2px 20px 0px #00000014",
  "& .course-wrapper": {
    display: "flex",
    flexDirection: 'column',
    gap: "32px",
    margin: '0 auto',
    padding: "56px 32px 64px 32px",
  },
  "& .title": {
    fontFamily: 'Crimson Text',
    fontSize: "64px",
    color: "#000000",
    fontWeight: 400,
    lineHeight: "80px"
  },
  "& .author": {
    fontFamily: "Heebo",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "23.5px",
    color: "#1C1C1C",
  }
})

const CourseDescription = styled(Box)({
  margin: '0 auto',
  maxWidth: "1440px",
  padding: "55px 32px 0 32px",
  position: "relative",
  display: "flex",
  gap: 8,
  "& .course-description": {
    fontFamily: "Crimson Text",
    fontSize: "20px",
    fontWeight: 400,
    lineHeight: "32px",
    color: "#1C1C1C",
    width: "100%",
    "& .editor": {
      position: 'absolute',
      top: 0,
      left: '50%'
    }
  }
});

const CustomInput = styled(InputBase)({
  height: "32px",
  "& input": {
    height: "100%",
  }
});

const Wrapper = styled(Box)({
  padding: "0 0 64px 0",
  "& .placeholder": {
    color: "#A8A29E"
  },
  "& .margin0": {
    "& h1, h2, h3, h4, p": {
      margin: "0px"
    }
  }
})

// Customizable Area End
