import React from "react";

// Customizable Area Start
import Header from '../../../components/src/Header.web';
import {
    Box
} from "@mui/material";
import HtmlReactParser from 'html-react-parser';
// Customizable Area End

import PrivacyPolicyUserPartController, {
    Props,
    configJSON,
} from "./PrivacyPolicyUserPartController";
import Loader from "../../../components/src/Loader.web";

export default class PrivacyPolicyUserPart extends PrivacyPolicyUserPartController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    // Customizable Area End

    render() {
        // Customizable Area Start
        return (
            <div>
                <Loader loading={this.state.loading}/>
                <Header
                    navigation={this.props.navigation}
                    islogin={true}
                    isHeader={false}
                >
                    <Box sx={styles.container}>
                        <Box sx={styles.content}>
                            <Box sx={styles.title}>Privacy policy</Box>
                            <Box sx={styles.textContent}>{HtmlReactParser(this.state.privacyPolicyValue)}</Box>
                        </Box>
                    </Box>
                </Header>
            </div>
        )
        // Customizable Area End
    }
}

// Customizable Area Start

const styles = {
    container: {
        padding: "30px 200px 24px 200px"
    },
    content: {

    },
    title: {
        fontFamily: "Inter",
        fontSize: "24px",
        fontWeight: 700,
        lineHeight: "32px",
        letterSpacing: "-0.005em",
    },
    textContent: {
        padding: "24px 0",
        fontFamily: "Heebo",
        fontSize: "16px",
        lineHeight: "24px",
    }
}

// Customizable Area End  